<template>
	<div class="loginback" :style="'background: url(\'http://xinqingimg.deaso40.com/%E8%83%8C%E6%99%AF.png\');background-size: ;background-position:50%;'">
		<div class="loginwindow">
			<div class="rightback" v-loading="loading">
				<div class="welcometext">
					新晴雀庄后台管理系统
				</div>
				<el-input placeholder="请输入账号" v-model="user" class="idinput">
					<i style="color:#3a62d7;" slot="prepend" class="el-icon-user-solid"></i>
				</el-input>
				<el-input placeholder="请输入密码" v-model="password" class="pwinput" :show-password="true">
					<i style="color:#3a62d7;" slot="prepend" class="el-icon-lock"></i>
				</el-input>
				<el-button type="primary" class="loginbutton" @click="login">登录</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import api from '@/api';
import crypto from 'crypto';

export default {
	data(){
		return{
			user: '',
			password: '',
			code: '',
			checked: false,
			loading: false
		}
  },
  props:['name'],
	methods:{
		async login(){
			this.loading = true;
			var userinfo;
			try{
				userinfo = await api.login("/adminlogin", {
					user: this.user,
					password: this.password
				});
				console.log(userinfo);
				// console.info(JSON.stringify(userinfo.data).replace(/,/g, ",\n")) // 转成json，方便复制
				localStorage.setItem('xinqing_admin_userInfo', userinfo.token);
				this.$message.success('登录成功');
				this.$router.push('/mainpage');
				this.loading = false;
			}catch(e){
				console.error(e);
				this.$message.error('登录失败');
				this.loading = false;
			}
		}
	}
};
</script>


<style scoped lang="less">
.loginback{
	min-width: 100vw;
	min-height: 100vh;
}
.loginwindow{
	position: absolute;
	width: 480px;
	height: 320px;
	top: 50%;
	left: 50%;
	margin-left: -240px;
	margin-top: -160px;
	// background-color: white;
	overflow: hidden;
}
.rightback{
	position: absolute;
	width: 480px;
	height: 320px;
	background-color: white;
	.welcometext{
		text-align: center;
		margin-top: 40px;
		margin-bottom: 40px;
		font-size: 22px;
	}
	.idinput{
		margin-left: 60px;
		width: 360px;
	}
	.pwinput{
		margin-top: 16px;
		margin-left: 60px;
		width: 360px;
	}
	.loginbutton{
		margin-top: 20px;
		width: 360px;
		margin-left: 60px;
	}
}
</style>
